import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, TextField, Typography } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

export default function EditSuggestion() {
    const location = useLocation()
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const oldTitleName = searchParams.get('topic')
    const oldDescription = searchParams.get('description')
    const oldURL = searchParams.get('url')
    const oldImageURL = searchParams.get('imageURL')
    const action = searchParams.get('action')
    const topicId = searchParams.get('id')
    const [newName, setNewName] = useState(oldTitleName)
    const [newDesc, setNewDesc] = useState(oldDescription)
    const [newURl, setNewURL] = useState(oldURL)
    const [newImageURL, setNewImageURL] = useState(oldImageURL)

    useEffect(() => {
        // Fetch data or set initial values if needed
    }, []) // Add dependencies if needed

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/suggestion/${topicId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: newName,
                    description: newDesc.trim(),
                    url: newURl.trim(),
                    imageURL: newImageURL.trim()
                })
            })
            if (response.ok) {
                console.log('Subject modified successfully')
                setNewName('')
                setNewImageURL('')
                navigate('/suggestions')
            } else {
                throw new Error('Failed to modify subject')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleDeleteTopic = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/suggestion/${topicId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.ok) {
                navigate(-1)
            } else {
                throw new Error('Failed to modify subject')
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ marginY: 2 }}>
                        Edit Suggestion
                    </Typography>
                    {action === 'edit' && (
                        <>
                            <TextField label='Suggestion' value={newName} onChange={(event) => setNewName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <TextField label='Description' value={newDesc} onChange={(event) => setNewDesc(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <TextField label='URL' value={newURl} onChange={(event) => setNewURL(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <TextField label='Image URL' value={newImageURL} onChange={(event) => setNewImageURL(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <Button sx={{ mt: 2 }} variant='contained' color='primary' onClick={handleSaveChanges}>
                                Save Changes
                            </Button>
                        </>
                    )}
                    {action === 'delete' && (
                        <>
                            <TextField label='Suggestion' value={newName} onChange={(event) => setNewName(event.target.value)} fullWidth variant='outlined' margin='normal' disabled />
                            <Button sx={{ mt: 2 }} variant='contained' color='error' onClick={handleDeleteTopic}>
                                Delete Suggestion
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

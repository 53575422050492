import React, { useState, useEffect } from 'react';
import {
    Box, Button, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Modal, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import DrawerNav from '../DrawerNav/DrawerNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { BASE_URL } from '../../Api/Constants';
import { showTimeInAgoFormat } from '../../Utils/Time';

function Articles() {
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [open, setOpen] = useState(false);
    const [editingArticle, setEditingArticle] = useState(null);
    const [selectedText, setSelectedText] = useState('');
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        fetchArticles(page);
        fetchCategories();
    }, [page]);

    useEffect(() => {
        setFilteredArticles(
            articles.filter(
                article =>
                    article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    article.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    categories.find(cat => cat._id === article.categoryId)?.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, articles, categories]);

    const fetchArticles = async (page) => {
        if (isLoading) return;

        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/upsc-short/articles?page=${page}`);
            const newArticles = response.data.data.list;

            setArticles(prevArticles => [...prevArticles, ...newArticles]);
            setHasMore(newArticles.length > 0); // If no more articles are returned, set hasMore to false
        } catch (error) {
            toast.error('Failed to fetch articles', { position: 'bottom-left' });
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/upsc-short/categories`);
            setCategories(response.data.data.list);
        } catch (error) {
            toast.error('Failed to fetch categories', { position: 'bottom-left' });
        }
    };

    const handleAdd = () => {
        setEditingArticle({ categoryId: '', title: '', description: '', imageURL: '', sourceURL: '' });
        setOpen(true);
    };

    const handleEdit = (article) => {
        setEditingArticle(article);
        setOpen(true);
    };

    const handleDelete = async (articleId) => {
        try {
            await axios.delete(`${BASE_URL}/api/v1/upsc-short/article/${articleId}`);
            setArticles(articles.filter(article => article._id !== articleId));
            toast.success('Article deleted successfully', { position: 'bottom-left' });
        } catch (error) {
            toast.error('Failed to delete article', { position: 'bottom-left' });
        }
    };

    const handleSave = async () => {
        try {
            const { categoryId, title, description, imageURL, sourceURL } = editingArticle;

            if (editingArticle._id) {
                const updatedArticle = { categoryId, title, description, imageURL, sourceURL }; // Only include required fields

                await axios.put(`${BASE_URL}/api/v1/upsc-short/article/${editingArticle._id}`, updatedArticle);
                setArticles(articles.map(article => (article._id === editingArticle._id ? { ...article, ...updatedArticle } : article)));
                toast.success('Article updated successfully', { position: 'bottom-left' });
            } else {
                await axios.post(`${BASE_URL}/api/v1/upsc-short/article`, { categoryId, title, description, imageURL, sourceURL });
                fetchArticles(1); // Reset to the first page after adding a new article
                toast.success('Article added successfully', { position: 'bottom-left' });
            }
            setOpen(false);
        } catch (error) {
            toast.error(`Failed to ${editingArticle._id ? 'update' : 'add'} article`, { position: 'bottom-left' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditingArticle(prev => ({ ...prev, [name]: value }));
    };

    const handleTextClick = (text) => {
        setSelectedText(text);
        setTextModalOpen(true);
    };

    const [textModalOpen, setTextModalOpen] = useState(false);

    const handleCloseTextModal = () => {
        setTextModalOpen(false);
        setSelectedText('');
    };

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading || !hasMore) return;
        setPage(prevPage => prevPage + 1);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, hasMore]);

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                    Articles
                </Typography>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
                    <TextField
                        size='small'
                        variant='outlined'
                        placeholder='Search...'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ backgroundColor: 'white', width: '300px' }}
                    />
                    <Button variant='contained' color='success' onClick={handleAdd}>
                        Add Article
                    </Button>
                </Box>
                <TableContainer component={Paper} sx={{ boxShadow: 3, overflowX: 'auto' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#262726' }}>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>S.No</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Category</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Title</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Image URL</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Source URL</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Created At</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Edit</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredArticles.map((article, index) => (
                                <TableRow key={article._id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                    <TableCell sx={{ cursor: 'pointer', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => handleTextClick(article.sourceURL)}>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell sx={{ cursor: 'pointer', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => handleTextClick(categories.find(cat => cat._id === article.categoryId)?.name)}>
                                        {categories.find(cat => cat._id === article.categoryId)?.name}
                                    </TableCell>
                                    <TableCell sx={{ cursor: 'pointer', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => handleTextClick(article.title)}>
                                        {article.title}
                                    </TableCell>
                                    <TableCell sx={{ cursor: 'pointer', maxWidth: '20px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => handleTextClick(article.description)}>
                                        {article.description}
                                    </TableCell>
                                    <TableCell sx={{ cursor: 'pointer', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => handleTextClick(article.imageURL)}>
                                        {article.imageURL}
                                    </TableCell>
                                    <TableCell sx={{ cursor: 'pointer', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => handleTextClick(article.sourceURL)}>
                                        {article.sourceURL}
                                    </TableCell>
                                    <TableCell sx={{ cursor: 'pointer', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => handleTextClick(article.sourceURL)}>
                                        {showTimeInAgoFormat(article.createdAt)}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEdit(article)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDelete(article._id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {isLoading && <Typography sx={{ textAlign: 'center', py: 2 }}>Loading more articles...</Typography>}
                </TableContainer>
            </Box>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={{ ...modalStyle, width: '70%' }}>
                    <Typography variant='h6' component='h2'>
                        {editingArticle?._id ? 'Edit Article' : 'Add Article'}
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={editingArticle?.categoryId || ''}
                            onChange={handleChange}
                            name="categoryId"
                            label="Category"
                        >
                            {categories.map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin='normal'
                        label='Title'
                        fullWidth
                        name='title'
                        value={editingArticle?.title || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin='normal'
                        label='Description'
                        fullWidth
                        name='description'
                        value={editingArticle?.description || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin='normal'
                        label='Image URL'
                        fullWidth
                        name='imageURL'
                        value={editingArticle?.imageURL || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin='normal'
                        label='Source URL'
                        fullWidth
                        name='sourceURL'
                        value={editingArticle?.sourceURL || ''}
                        onChange={handleChange}
                    />
                    <Button variant='contained' color='primary' sx={{ mt: 2 }} onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Modal>
            <Modal open={textModalOpen} onClose={handleCloseTextModal}>
                <Box sx={{ ...modalStyle, width: '70%', overflowX: 'scroll' }}>
                    <Typography variant='body1' component='p'>
                        {selectedText}
                    </Typography>
                    <Button variant='contained' color='primary' sx={{ mt: 2 }} onClick={handleCloseTextModal}>
                        Close
                    </Button>
                </Box>
            </Modal>
            <ToastContainer />
        </Box>
    );
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default Articles;

import React, { useState, useEffect } from 'react';
import {
    Box, Button, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Modal
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import DrawerNav from '../DrawerNav/DrawerNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { BASE_URL } from '../../Api/Constants';

function Categories() {
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [open, setOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        setFilteredCategories(
            categories.filter(
                category =>
                    category.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, categories]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/upsc-short/categories`);
            setCategories(response.data.data.list);
        } catch (error) {
            toast.error('Failed to fetch categories', { position: 'bottom-left' });
        }
    };

    const handleAdd = () => {
        setEditingCategory({ name: '', imageURL: '' });
        setOpen(true);
    };

    const handleEdit = (category) => {
        setEditingCategory(category);
        setOpen(true);
    };

    const handleDelete = async (categoryId) => {
        try {
            await axios.delete(`${BASE_URL}/api/v1/upsc-short/category/${categoryId}`);
            setCategories(categories.filter(category => category._id !== categoryId));
            toast.success('Category deleted successfully', { position: 'bottom-left' });
        } catch (error) {
            toast.error('Failed to delete category', { position: 'bottom-left' });
        }
    };

    const handleSave = async () => {
        if (editingCategory._id) {
            try {
                await axios.put(`${BASE_URL}/api/v1/upsc-short/category/${editingCategory._id}`, { name: editingCategory.name, imageURL: editingCategory.imageURL});
                setCategories(categories.map(category => (category._id === editingCategory._id ? editingCategory : category)));
                toast.success('Category updated successfully', { position: 'bottom-left' });
            } catch (error) {
                toast.error('Failed to update category', { position: 'bottom-left' });
            }
        } else {
            try {
                await axios.post(`${BASE_URL}/api/v1/upsc-short/category`, editingCategory);
                fetchCategories();
                toast.success('Category added successfully', { position: 'bottom-left' });
            } catch (error) {
                toast.error('Failed to add category', { position: 'bottom-left' });
            }
        }
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditingCategory(prev => ({ ...prev, [name]: value }));
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                    Categories
                </Typography>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
                    <TextField
                        size='small'
                        variant='outlined'
                        placeholder='Search...'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ backgroundColor: 'white', width: '300px' }}
                    />
                    <Button variant='contained' color='success' onClick={handleAdd}>
                        Add Category
                    </Button>
                </Box>
                <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#262726' }}>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Image</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Edit</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredCategories.map((category) => (
                                <TableRow key={category._id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                    <TableCell>{category.name}</TableCell>
                                    <TableCell>
                                        <img src={category.imageURL} alt={category.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEdit(category)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDelete(category._id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={{ ...modalStyle, width: 400 }}>
                    <Typography variant='h6' component='h2'>
                        {editingCategory?._id ? 'Edit Category' : 'Add Category'}
                    </Typography>
                    <TextField
                        margin='normal'
                        label='Name'
                        fullWidth
                        name='name'
                        value={editingCategory?.name || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin='normal'
                        label='Image URL'
                        fullWidth
                        name='imageURL'
                        value={editingCategory?.imageURL || ''}
                        onChange={handleChange}
                    />
                    <Button variant='contained' color='primary' sx={{ mt: 2 }} onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Modal>
            <ToastContainer />
        </Box>
    );
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default Categories;

import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Grid, Button, MenuItem, TextField, Typography, FormControl, InputLabel, Select } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

export default function EditQuestion() {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const questionId = searchParams.get('id')
    const [question, setQuestion] = useState(null)
    const [editedQuestion, setEditedQuestion] = useState(null)
    const [topics, setTopics] = useState([])
    const [subtopics, setSubtopics] = useState([])

    useEffect(() => {
        const fetchQuestionDetails = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/admin/question/${questionId}`)
                if (response.ok) {
                    const data = await response.json()
                    console.log('Data : ', data.data.question)
                    setQuestion(data.data.question)
                    setEditedQuestion(data.data.question)
                    fetchSubtopics(data.data.question.topic)
                    console.log('data.data.question : ', data.data.question)
                } else {
                    throw new Error('Failed to fetch question details')
                }
            } catch (error) {
                console.error(error)
            }
        }

        if (questionId) {
            fetchQuestionDetails()
        }
    }, [questionId])

    const handleInputChange = (event, field) => {
        setEditedQuestion({
            ...editedQuestion,
            [field]: event.target.value
        })
        if (field === 'topic') fetchSubtopics(event.target.value)
    }

    const handleCheckboxChange = (event, property) => {
        setEditedQuestion((prevState) => ({
            ...prevState,
            [property]: event.target.checked
        }))
    }
    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/user/topics`)
                const data = await response.json()
                if (response.ok) {
                    console.log('Data : ', data)
                    setTopics(data.data.topicsList)
                } else {
                    console.error(data.message)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }

        fetchTopics()
    }, [])

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/question/${questionId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editedQuestion)
            })
            if (response.ok) {
                console.log('Question updated successfully')
                navigate(-1)
            } else {
                throw new Error('Failed to update question')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchSubtopics = async (newTopic) => {
        try {
            const response = await fetch(`${BASE_URL}/api/user/subtopics?topic=${newTopic}`)
            const data = await response.json()
            if (response.ok) {
                console.log('Subtopics Data : ', data)
                setSubtopics(data.data.subtopicsList)
            } else {
                console.error(data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    {editedQuestion && (
                        <>
                            <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                                Edit Question
                            </Typography>
                            <TextField multiline label='Question' value={editedQuestion.question} onChange={(event) => handleInputChange(event, 'question')} fullWidth variant='outlined' margin='normal' />
                            <FormControl fullWidth variant='outlined' margin='normal'>
                                <InputLabel id='select-topic-label'>Choose Topic</InputLabel>
                                <Select labelId='select-topic-label' id='select-topic' value={editedQuestion.topic} onChange={(event) => handleInputChange(event, 'topic')} label='Choose Topic'>
                                    {topics.map((topic, index) => (
                                        <MenuItem key={index} value={topic}>
                                            {topic}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant='outlined' margin='normal'>
                                <InputLabel id='select-subtopic-label'>Choose Subtopic</InputLabel>
                                <Select labelId='select-subtopic-label' id='select-subtopic' value={editedQuestion.subtopic} onChange={(event) => handleInputChange(event, 'subtopic')} label='Choose Subtopic'>
                                    {subtopics.map((topic, index) => (
                                        <MenuItem key={index} value={topic}>
                                            {topic}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography variant='body1'>Options:</Typography>
                            <Grid container spacing={0.5}>
                                {editedQuestion.options &&
                                    editedQuestion.options.map((option, index) => (
                                        <Grid item xs={12} md={6} key={index}>
                                            <TextField
                                                value={option}
                                                onChange={(event) => {
                                                    const newOptions = [...editedQuestion.options]
                                                    newOptions[index] = event.target.value
                                                    setEditedQuestion({
                                                        ...editedQuestion,
                                                        options: newOptions
                                                    })
                                                }}
                                                fullWidth
                                                variant='outlined'
                                                margin='normal'
                                                style={{ backgroundColor: option === editedQuestion.correctOption ? '#c0f0c2' : 'white' }}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                            {editedQuestion.options.some((option) => option.trim().length > 0) && (
                                <TextField
                                    select
                                    sx={{ my: 1 }}
                                    name=''
                                    label='Correct Option'
                                    fullWidth
                                    SelectProps={{
                                        native: true
                                    }}
                                    value={editedQuestion.correctOption}
                                    onChange={(event) => handleInputChange(event, 'correctOption')}>
                                    {editedQuestion.options.map(
                                        (option, index) =>
                                            option.trim().length > 0 && (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            )
                                    )}
                                </TextField>
                            )}
                            <TextField label='Explanation' multiline={true} value={editedQuestion.explanation} onChange={(event) => handleInputChange(event, 'explanation')} fullWidth variant='outlined' margin='normal' />
                            <Box mt={2}>
                                <Button variant='contained' color='primary' onClick={handleSaveChanges}>
                                    Save Changes
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'

export default function AddQuestion() {
    const [question, setQuestion] = useState('')
    const [topic, setTopic] = useState('')
    const [subtopic, setSubtopic] = useState('')
    const [section, setSection] = useState('')
    const [optionA, setOptionA] = useState('')
    const [optionB, setOptionB] = useState('')
    const [optionC, setOptionC] = useState('')
    const [optionD, setOptionD] = useState('')
    const [correctOption, setCorrectOption] = useState('')
    const [explanation, setExplanation] = useState('')
    const [topics, setTopics] = useState([])
    const [subtopics, setSubtopics] = useState([])
    const navigate = useNavigate()

    const options = [
        { value: optionA, label: optionA },
        { value: optionB, label: optionB },
        { value: optionC, label: optionC },
        { value: optionD, label: optionD }
    ]

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/user/topics`)
                const data = await response.json()
                if (response.ok) {
                    console.log('Data : ', data)
                    setTopics(data.data.topicsList)
                } else {
                    console.error(data.message)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }

        fetchTopics()
    }, [])

    useEffect(() => {
        const fetchSubtopics = async () => {
            if (topic) {
                try {
                    const response = await fetch(`${BASE_URL}/api/user/subtopics?topic=${topic}`)
                    const data = await response.json()
                    if (response.ok) {
                        console.log('Subtopics Data : ', data)
                        setSubtopics(data.data.subtopicsList)
                    } else {
                        console.error(data.message)
                    }
                } catch (error) {
                    console.error('Error:', error)
                }
            }
        }

        fetchSubtopics()
    }, [topic])

    const handleSaveQuestion = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    question,
                    topic,
                    subtopic,
                    section,
                    options: [optionA, optionB, optionC, optionD],
                    correctOption,
                    explanation
                })
            })

            const data = await response.json()

            if (response.ok) {
                setQuestion('')
                setTopic('')
                setSubtopic('')
                setOptionA('')
                setOptionB('')
                setOptionC('')
                setOptionD('')
                setCorrectOption('')
                setExplanation('')
                navigate('/')
            } else {
                console.error(data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add New Question
                    </Typography>
                    <TextField label='Question' multiline={true} value={question} onChange={(event) => setQuestion(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-topic-label'>Choose Section</InputLabel>
                        <Select labelId='select-topic-label' id='select-topic' value={section} onChange={(event) => setSection(event.target.value)} label='Choose Section'>
                            {['General Studies', 'Civil Services Aptitude', 'Current Affairs', 'Past Year Questions'].map((topic, index) => (
                                <MenuItem key={index} value={topic}>
                                    {topic}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-topic-label'>Choose Topic</InputLabel>
                        <Select labelId='select-topic-label' id='select-topic' value={topic} onChange={(event) => setTopic(event.target.value)} label='Choose Topic'>
                            {topics.map((topic, index) => (
                                <MenuItem key={index} value={topic}>
                                    {topic}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-subtopic-label'>Choose Subtopic</InputLabel>
                        <Select labelId='select-subtopic-label' id='select-subtopic' value={subtopic} onChange={(event) => setSubtopic(event.target.value)} label='Choose Subtopic'>
                            {subtopics.map((topic, index) => (
                                <MenuItem key={index} value={topic}>
                                    {topic}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label='Option A'
                        value={optionA}
                        onChange={(event) => setOptionA(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                        sx={{ backgroundColor: correctOption === 'A' ? '#9cda7d' : 'inherit' }}
                    />
                    <TextField
                        label='Option B'
                        value={optionB}
                        onChange={(event) => setOptionB(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                        sx={{ backgroundColor: correctOption === 'B' ? '#9cda7d' : 'inherit' }} // Apply green background if correctOption is 'B'
                    />
                    <TextField
                        label='Option C'
                        value={optionC}
                        onChange={(event) => setOptionC(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                        sx={{ backgroundColor: correctOption === 'C' ? '#9cda7d' : 'inherit' }} // Apply green background if correctOption is 'C'
                    />
                    <TextField
                        label='Option D'
                        value={optionD}
                        onChange={(event) => setOptionD(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                        sx={{ backgroundColor: correctOption === 'D' ? '#9cda7d' : 'inherit' }} // Apply green background if correctOption is 'D'
                    />

                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-correct-option-label'>Correct Option</InputLabel>
                        <Select labelId='select-correct-option-label' id='select-correct-option' value={correctOption} onChange={(event) => setCorrectOption(event.target.value)} label='Correct Option'>
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField label='Explanation' multiline={true}  value={explanation} onChange={(event) => setExplanation(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveQuestion}>
                            Add Question
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

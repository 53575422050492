import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import Home from './Components/Home/Home'
import Header from './Components/Header/Header'
import AddQuestion from './Components/AddQuestion/AddQuestion'
import AddTopic from './Components/AddTopic/AddTopic'
import TopicQuestions from './Components/TopicQuestions/TopicQuestions'
import Login from './Components/Login/Login'
import isAuthenticated from './Auth/AuthenticateUser'
import DeletedTopics from './Components/DeletedTopics/DeletedTopics'
import RecentActivity from './Components/RecentActivity/RecentActivity'
import EditQuestion from './Components/EditQuestion/EditQuestion'
import AddOnboarding from './Components/AddOnboarding/AddOnboarding'
import ViewOnboarding from './Components/ViewOnboarding/ViewOnboarding'
import EditOnboarding from './Components/EditOnboarding/EditOnboarding'
import EditTopic from './Components/EditTopic/EditTopic'
import AddExam from './Components/AddExam/AddExam'
import ViewExams from './Components/ViewExams/ViewExams'
import EditExam from './Components/EditExam/EditExam'
import EditUser from './Components/EditUser/EditUser'
import Users from './Components/JoinedUsers/JoinedUsers'
import ViewTopics from './Components/ViewTopics/ViewTopics'
import AddSubtopic from './Components/AddSubtopic/AddSubtopic'
import ViewSubtopics from './Components/ViewSubtopics/ViewSubtopics'
import EditSubtopic from './Components/EditSubtopic/EditSubtopic'
import ViewSection from './Components/ViewSection/ViewSection'
import ViewSectionTopics from './Components/ViewSectionTopics/ViewSectionTopics'
import QuestionsList from './Components/QuestionsList/QuestionsList'
import DeleteQuestion from './Components/DeleteQuestion/DeleteQuestion'
import ViewSuggestions from './Components/ViewSuggestions/ViewSuggestions'
import AddSuggestion from './Components/AddSuggestion/AddSuggestion'
import EditSuggestion from './Components/EditSuggestion/EditSuggestion'
import Articles from './Components/V2/Articles'
import Categories from './Components/V2/Categories'

function App() {
    const PrivateWrapper = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
    }
    return (
        <BrowserRouter>
            <div>
                <Header />
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/' element={<Home />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/articles' element={<Articles />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/categories' element={<Categories />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-question' element={<AddQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-topic' element={<AddTopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-suggestion' element={<AddSuggestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-subtopic' element={<AddSubtopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topics' element={<ViewTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/suggestions' element={<ViewSuggestions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/section' element={<ViewSection />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic' element={<ViewSectionTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/subtopics' element={<ViewSubtopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/questions-list' element={<QuestionsList />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/users' element={<Users />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic-questions/:id' element={<TopicQuestions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/deleted-topics' element={<DeletedTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/recent-activity' element={<RecentActivity />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-question' element={<EditQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-onboarding' element={<AddOnboarding />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/view-onboarding' element={<ViewOnboarding />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/onboarding' element={<EditOnboarding />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-topic' element={<EditTopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-subtopic' element={<EditSubtopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-suggestion' element={<EditSuggestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-exam' element={<AddExam />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/view-exams' element={<ViewExams />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/exam' element={<EditExam />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-user' element={<EditUser />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/delete-question' element={<DeleteQuestion />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App

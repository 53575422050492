/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Box } from '@mui/material'
import './Home.css'
import DrawerNav from '../DrawerNav/DrawerNav'
import { useNavigate } from 'react-router-dom'

function Home() {
    const navigate = useNavigate()
    const sectionsList = ['General Studies', 'Civil Services Aptitude', 'Current Affairs', 'Past Year Questions']

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const handleSectionClick = (text) => {
        navigate('/section?name=' + text)
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48'>
                        <h1 className='text-3xl font-bold pb-8'>Topics</h1>
                        <div className='grid md:grid-cols-4 gap-4'>
                            {sectionsList.map((topic, index) => {
                                return (
                                    <div className='flex justify-center items-center cursor-pointer' onClick={() => handleSectionClick(topic)}>
                                        <div className='w-full max-w-sm rounded-lg shadow bg-gray-800 border-gray-700 hover:bg-gray-900'>
                                            <div className='flex flex-col items-center pb-6'>
                                                <img className='w-24 h-24 mb-3 mt-6 rounded-full shadow-lg' src='/vite.svg' alt='Bonnie image' />
                                                <h5 className='mb-1 text-xl font-medium text-white'>{topic}</h5>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default Home
